





















































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import QrScanner from 'qr-scanner';

import Loader from '@/shared/resources/components/Loader.vue';
import Popup from '@/shared/lib/support/popups/Popup';
import Alert from '@/shared/resources/components/Alert.vue';
import QRReaderConfirm from './QRReaderConfirm.vue';
import QRCodeCoreModel from '@/modules/qr-codes/models/QRCodeCoreModel';
import ApiErrorResponses from '@/core/api/errors/ApiErrorResponses';
import SideModal from '@/shared/resources/components/SideModal.vue';
import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';
import ApiORMQueryBuilder from '@/core/bridge/orm/api/ApiORMQueryBuilder';
import LoaderOverlay from '@/shared/resources/components/LoaderOverlay.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';

@Component({
  components: {
    GridCol,
    GridRow,
    LoaderOverlay,
    SideModal,
    Alert,
    Loader,
    QRReaderConfirm,
  },
})
export default class QRReader extends Vue {
  /**
   * Props
   */
  @Prop() private query!: ApiORMQueryBuilder;

  /**
   * Refs
   */
  @Ref() private readonly videoElement!: HTMLVideoElement;

  /**
   * Data
   */
  private qrScanner!: QrScanner;
  private qrCode: QRCodeCoreModel | null = null;
  private hasCamera: boolean | null = null;
  private modalShown: boolean = false;
  private fetchingLoading: boolean = false;
  private loading: boolean = false;
  private showBlink: boolean = false;

  /**
   * Display getters
   */
  private get displayReader(): boolean {
    return this.hasCamera !== false;
  }

  private get displayError(): boolean {
    return !!this.errorMessage;
  }

  private get displayLoader(): boolean {
    return this.loading;
  }

  private get displayFetchingLoader(): boolean {
    return this.fetchingLoading;
  }

  /**
   * Getters
   */
  private get used(): number {
    return QRCodeCoreModel
      .query()
      .where('isUsed', true)
      .get()
      .length;
  }

  private get total(): number {
    return QRCodeCoreModel
      .query()
      .all()
      .length;
  }

  private get errorMessage(): string | null {
    if (this.hasCamera === false) {
      return 'Nie znaleziono kamery w urządzeniu.';
    }

    return null;
  }

  /**
   * Lifecycle hooks
   */
  private async created() {
    this.fetchQRCodes();
  }

  private async mounted() {
    await this.checkHasCamera();
    this.createQRScanner();

    // await new Promise((resolve: any) => setTimeout(resolve, 1000));
    // this.stopQRScanner();
    // this.handleScanResult({ data: '' });
  }

  private destroyed() {
    this.stopQRScanner();
  }

  /**
   * Methods
   */
  private async checkHasCamera() {
    this.hasCamera = await QrScanner.hasCamera();
  }

  private createQRScanner() {
    const onDecode = (result: string) => {
      this.stopQRScanner();

      setTimeout(() => {
        this.handleScanResult(result);
      }, 500);
    };

    this.qrScanner = new QrScanner(
      this.videoElement,
      onDecode,
      {
        onDecodeError: (error: any) => null,
        highlightScanRegion: true,
        maxScansPerSecond: 1,
      } as any,
    );

    this.qrScanner.start();
  }

  private async handleScanResult(result: any) {
    this.loading = true;

    try {
      this.qrCode = await this.query
        .param('id', result.data)
        .get();

      this.qrScanner.stop();
      this.showModal();
    } catch (e) {
      let errorMessage = 'Wystąpił nieznany błąd.';

      if (e instanceof ApiErrorResponses) {
        if (e.isNotFound()) {
          errorMessage = 'Nie znaleziono kodu';
        }
      }

      await Popup.error(errorMessage);
      this.startQRScanner();
    }

    this.loading = false;
  }

  private async fetchQRCodes() {
    return; // TODO

    this.fetchingLoading = true;

    await QRCodeCoreModel
      .apiExt()
      .fetch();

    this.fetchingLoading = false;
  }

  private showModal() {
    this.modalShown = true;
  }

  private hideModal() {
    this.modalShown = false;
  }

  private startQRScanner() {
    if (!this.hasCamera) {
      return;
    }

    this.qrScanner.start();

    this.showBlink = false;
    this.videoElement.play();
    this.qrScanner.start();
  }

  private stopQRScanner() {
    if (!this.qrScanner) {
      return;
    }

    this.qrScanner.stop();

    this.showBlink = true;

    if (this.videoElement) {
      this.videoElement.pause();
    }
  }

  /**
   * Handlers
   */
  private onModalHide() {
    this.hideModal();
    this.startQRScanner();
    this.fetchQRCodes();
  }
}
